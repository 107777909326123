import { OptionalDate } from "@silversea-ssc/dotcom-api-types/OptionalDate";
import { Language, OgLangCode } from "../localization/languages/types";

function toUTCDate(date: undefined | null): undefined;
function toUTCDate(date: Date | string): Date;
function toUTCDate(date: Date | string | undefined | null): Date | undefined {
  if (!date) return undefined;
  if (typeof date === "string") date = new Date(date);

  return new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      0,
      0
    )
  );
}

export type FormatDateFormat = Intl.DateTimeFormatOptions;

function formatDate(
  date: undefined,
  language: string,
  format: FormatDateFormat
): string;
function formatDate(
  date: Date | string,
  language: string,
  format: FormatDateFormat
): string;
function formatDate(
  date: Date | string | undefined,
  language: string,
  format: FormatDateFormat
): string {
  if (!date) return "";
  const dateFormat = { ...format, timeZone: "UTC" };

  const utcDate = toUTCDate(date);

  try {
    if (
      typeof Intl === "undefined" ||
      typeof Intl.DateTimeFormat === "undefined"
    )
      return utcDate.toLocaleDateString(language, format);

    return new Intl.DateTimeFormat(language, dateFormat).format(utcDate);
  } catch {
    //invalid date or not supported
    return date.toString();
  }
}

function formatLocalDate(
  date: Date,
  locale: Intl.LocalesArgument,
  format: FormatDateFormat
): string {
  const localFormat = { ...format, timeZone: undefined };
  const dateTimeFormat = new Intl.DateTimeFormat(locale, localFormat);
  return dateTimeFormat.format(date);
}

function formatDateRange(
  startDate: Date,
  endDate: Date,
  language: Language,
  format: FormatDateFormat
): string[] {
  const dateTimeFormat = new Intl.DateTimeFormat(language.ogLang, {
    ...format,
    calendar: "gregory",
    timeZone: "UTC"
  });

  try {
    return dateTimeFormat.formatRange(startDate, endDate).split("–");
  } catch (_e) {
    return [
      formatDate(startDate, language.ogLang, format),
      formatDate(endDate, language.ogLang, format)
    ];
  }
}

function getMonthNames(
  locale: OgLangCode,
  format:
    | "numeric"
    | "2-digit"
    | "long"
    | "short"
    | "narrow"
    | undefined = "short"
) {
  const formatter = new Intl.DateTimeFormat(locale, {
    month: format,
    timeZone: "UTC"
  });
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => {
    const mm = month < 10 ? `0${month}` : month;
    return new Date(`2017-${mm}-15T00:00:00+00:00`);
  });
  return months.map((date) => formatter.format(date));
}

const dateFromOptionalDate = (date: OptionalDate): Date | undefined => {
  if (date.type == "givenDate") return new Date(`${date.date}T23:59:59.999Z`);

  return undefined;
};

const longDateTimeWeekDayFormat: FormatDateFormat = {
  day: "2-digit",
  month: "long",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  weekday: "long"
};

const shortDateTimeFormat: FormatDateFormat = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit"
};

const dateOnlyISOString = (date: Date): string =>
  date.toISOString().substring(0, 10);

// eslint-disable-next-line import/no-unused-modules
export {
  dateOnlyISOString,
  getMonthNames,
  toUTCDate,
  formatDate,
  formatLocalDate,
  formatDateRange,
  dateFromOptionalDate,
  longDateTimeWeekDayFormat,
  shortDateTimeFormat
};

import React, { PropsWithChildren, useContext, useState } from "react";
import type { Configuration } from "rollbar";

import Rollbar from "rollbar/src/browser/core";
import telemeter from "rollbar/src/telemetry";
import instrumenter from "rollbar/src/browser/telemetry";
import truncation from "rollbar/src/truncation";

Rollbar.setComponents({
  telemeter: telemeter,
  instrumenter: instrumenter,
  //polyfillJSON: polyfillJSON,
  //wrapGlobals: wrapGlobals,
  //scrub: scrub,
  truncation: truncation
});

type RollbarInstanceProviderContextValue = Rollbar | undefined;

const RollbarInstanceProviderContext =
  React.createContext<RollbarInstanceProviderContextValue>(undefined);

type Props = { config: Configuration };

export const RollbarInstanceProvider: React.FC<PropsWithChildren<Props>> = ({
  children,
  config
}) => {
  const [rollbar] = useState<Rollbar | undefined>(() => {
    if (!config.enabled) return undefined;

    return Rollbar.init(config);
  });

  return (
    <RollbarInstanceProviderContext.Provider value={rollbar}>
      {children}
    </RollbarInstanceProviderContext.Provider>
  );
};

export const useRollbarInstance = () => {
  return useContext(RollbarInstanceProviderContext);
};

import {
  QueryFunctionContext,
  QueryStatus,
  useQuery,
  UseQueryResult
} from "@tanstack/react-query";
import { isPresent } from "src/utils/checks";
import { Language } from "src/utils/localization/languages/types";
import { customLocationValuesFromSearch } from "src/utils/localization/geolocation/geolocationLoader";
import { stringPresent } from "src/utils/strings";

export type FetchApiStatus =
  | "idle"
  | "pending"
  | "loading"
  | "success"
  | "error";

export interface UseFetchDataResponse<T> {
  isLoading: boolean;
  isError: boolean;
  status: FetchApiStatus;
  //statusCode?: number;
  data?: T;
}

const transformStateBuilder =
  <T, V>(dataTransformer: (data: T) => V) =>
  (state: UseFetchDataResponse<T>): UseFetchDataResponse<V> => ({
    ...state,
    data: state.data ? dataTransformer(state.data) : undefined
  });

export const useFetchApiAndTransform = <T, V>(
  request: RequestInfo | null | undefined,
  dataTransformer: (data: T) => V,
  cacheKey?: (string | undefined)[]
): UseFetchDataResponse<V> => {
  const state = useFetchApi<T>(request, cacheKey);
  const transformState = transformStateBuilder(dataTransformer);

  return transformState(state);
};

// // eslint-disable-next-line import/no-unused-modules
// export const useMultipleFetchApi = <T>(
//   requests: RequestInfo[]
// ): UseFetchDataResponse<T[]> => {
//   const userQueries = useQueries({
//     queries: requests.map((request) => ({
//       enabled: isPresent(request),
//       queryKey: [JSON.stringify(request)],
//       queryFn: fetchFunction<T>(request)
//     }))
//   });

//   const isLoading = userQueries.some(({ isLoading }) => isLoading);
//   const isError = userQueries.some(({ isError }) => isError);
//   const data = userQueries.reduce((acc, { data }) => {
//     if (data) acc.push(data as T);
//     return acc;
//   }, [] as T[]);

//   const result: UseFetchDataResponse<T[]> = {
//     isLoading,
//     isError,
//     status: isLoading ? "loading" : isError ? "error" : "success",
//     data: data
//   };

//   return result;
// };

export const fetchFunction =
  <T>(request: RequestInfo | null | undefined) =>
  async (_context: QueryFunctionContext): Promise<T | undefined> => {
    if (!request) return;

    const response = await fetch(request, {
      headers: {
        Accept: "application/json"
      }
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.json();
  };

const toUseFetchDataResponse = <T>(
  useQueryResult: UseQueryResult<unknown, unknown>
): UseFetchDataResponse<T> => {
  const { isLoading, isError, data, status } = useQueryResult;
  const result: UseFetchDataResponse<T> = {
    isLoading: isLoading || status == "pending",
    isError,
    status: toFetchApiStatus(status, isLoading, isError),
    data: data as T
  };

  return result;
};

const toFetchApiStatus = (
  queryResultStatus: QueryStatus,
  isLoading: boolean,
  isError: boolean
): FetchApiStatus => {
  if (isLoading) return "loading";

  if (isError) return "error";

  return queryResultStatus;
};

export const useFetchApi = <T>(
  request: RequestInfo | null | undefined,
  cacheKey?: (string | undefined)[],
  enableRetry = true
): UseFetchDataResponse<T> => {
  const validKeys = cacheKey?.filter(isPresent) || [];
  const url = request instanceof Request ? request.url : request;
  const result = useQuery({
    retry: enableRetry,
    queryKey: [url, ...validKeys],
    enabled: isPresent(request),
    queryFn: fetchFunction(request)
  });

  return toUseFetchDataResponse<T>(result);
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const basePath = process.env.GATSBY_DIGITAL_API_BASE_URL!;

export const buildGetRequest = <T>(
  resourceName: string,
  params?: T
): string => {
  const resource = resourceName[0] == "/" ? resourceName : `/${resourceName}`;
  const queryString = params
    ? `?${new URLSearchParams(filterUndefinedParams(params)).toString()}`
    : "";

  return basePath + resource + queryString;
};

const filterUndefinedParams = <T extends Record<string, unknown>>(
  params: T
): T => {
  return Object.keys(params).reduce<T>((acc, key: keyof T) => {
    const value = params[key];
    if (isPresent(value)) acc[key] = value;

    return acc;
  }, {} as T);
};

export const buildGeolocationGetRequest = <QueryParams>(
  resourceName: string,
  params: QueryParams,
  querySearch: string | undefined | null,
  language?: Language
): string => {
  const enhancedParams = stringPresent(querySearch)
    ? customLocationValuesFromSearch(querySearch)
    : {};

  return buildGetRequest(resourceName, {
    ...enhancedParams,
    ...params,
    ...(language ? { language: language.sanityCode } : {})
  });
};

import { KeepBookingResponseEncoded } from "@silversea-ssc/dotcom-api-types/KeepBooking";
import { isEnvFeatureEnabled } from "src/utils/feature-flags";
import { useUserId } from "src/utils/UserIdProvider";
import { buildGetRequest, useFetchApi } from "src/ssc-api/useFetchApi";

const keepBookingForDev: KeepBookingResponseEncoded = {
  adults: 2,
  kids: 0,
  // state: "CourtesyHoldCreated",
  state: "GuestInfoVisited",
  cruiseCode: "SN250326014",
  fare: "DoorToDoor",
  returnUrl: "https://quote.silversea.com/v3/SN250326014",
  hasFlights: true,
  suiteCategory: "DX",
  suiteNumber: "123",
  bookingNumber: "123456-24",
  expirationDate: "2025-07-01T23:59:59.000Z"
};

export const useKeepBooking = () => {
  const userId = useUserId();
  const withMockedData = isEnvFeatureEnabled(
    process.env.GATSBY_MOCKED_KEEP_BOOKING
  );

  const response = useFetchApi(
    userId && !withMockedData
      ? new Request(buildGetRequest("keepBooking"), {
          method: "get",
          credentials: "include"
        })
      : undefined,
    undefined,
    false
  );

  if (withMockedData) return keepBookingForDev;

  return response.status === "success"
    ? (response.data as KeepBookingResponseEncoded)
    : undefined;
};

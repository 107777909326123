const inPageScrollIdentifier = "data-page-builder-anchor";

export const scrollToSection = (
  targetElement: HTMLDivElement | HTMLElement
): void => {
  setScrollOffset();
  targetElement.scrollIntoView({ block: "start", behavior: "smooth" });
};

export const scrollToSectionId = (targetElementId: string): void => {
  const element = document.getElementById(targetElementId);
  if (element) scrollToSection(element);
};

export const getAllAnchorableSections = (): NodeListOf<HTMLDivElement> =>
  document.querySelectorAll<HTMLDivElement>(`[${inPageScrollIdentifier}]`);

export const getAnchorableSectionByRefName = (
  navContainerItems: NodeListOf<HTMLDivElement>,
  target: string | undefined
): HTMLDivElement | undefined => {
  if (!target) return undefined;

  return navContainerItems
    ? Array.from(navContainerItems).find(
        (x) => x.getAttribute(inPageScrollIdentifier) == target
      )
    : undefined;
};

const getMainHeaderElement = () =>
  document.querySelector<HTMLElement>("header[data-main-header]");

const calculateStickyHeadersScrollOffset = (
  mainStickyHeaderTopOffset: number
): number => {
  const stickyNav = document.querySelector<HTMLElement>(
    "[data-secondary-sticky-menu]"
  );
  const stickyNavContent = document.querySelector<HTMLElement>(
    "[data-secondary-sticky-menu-children]"
  );

  const translateOffset = stickyNav?.clientHeight || 0;
  const translateContentOffset = stickyNavContent?.clientHeight || 0;
  return mainStickyHeaderTopOffset + translateOffset + translateContentOffset;
};

const isElementStickable = (element: HTMLElement | null): boolean =>
  element ? getComputedStyle(element).position == "sticky" : false;

export function setScrollOffset() {
  const mainHeader = getMainHeaderElement();
  const mainStickyHeaderTopOffset = computeStickyMainNavOffset(mainHeader);
  const stickyOffset = calculateStickyHeadersScrollOffset(
    mainStickyHeaderTopOffset
  );

  const rootElement = document.querySelector<HTMLElement>(":root");

  // this is the secondary sticky menu top offset
  rootElement?.style?.setProperty(
    "--sticky-menus-top-offset",
    stickyOffset > 0 ? `${stickyOffset}px` : null
  );

  const mainHeaderHeight = mainHeader?.clientHeight || 0;

  // this is the main header height
  rootElement?.style?.setProperty(
    "--main-header-top-offset",
    mainHeaderHeight > 0 ? `${mainHeaderHeight}px` : null
  );

  // this is the main sticky header top offset
  rootElement?.style?.setProperty(
    "--main-sticky-header-top-offset",
    mainStickyHeaderTopOffset > 0 ? `${mainStickyHeaderTopOffset}px` : null
  );
}

const computeStickyMainNavOffset = (mainHeader: HTMLElement | null): number => {
  if (!mainHeader) return 0;

  const isSticky = isElementStickable(mainHeader);
  return isSticky && mainHeader ? mainHeader.clientHeight : 0;
};

import React from "react";
import { useGeolocation } from "src/utils/localization/geolocation/GeolocationContext";
import { matchesAny } from "src/utils/localization/geolocation";
import { AirNonAirOrLocationFilterFragment } from "types/graphql-types";
import { PortableTextReactComponents } from "@portabletext/react";

const LinkToFile = React.lazy(() => import("./LinkToFile"));

const InlineLocalizedPhoneNumber = () => {
  const geoLocation = useGeolocation();
  return <span>{geoLocation?.phone}</span>;
};

const LocationSpecificTextSection: React.FC<
  React.PropsWithChildren<{
    value: {
      _key: string;
      _type: string;
      locationFilters: AirNonAirOrLocationFilterFragment[];
    };
  }>
> = ({ value, children }) => {
  const geolocation = useGeolocation();
  const countyCode = geolocation.countryCode;
  const isMatching = countyCode
    ? matchesAny(value.locationFilters, geolocation)
    : false;

  return <span>{isMatching ? children : ""}</span>;
};

export const customBlockContentComponents: Partial<PortableTextReactComponents> =
  {
    marks: {
      placeholder: ({ children }: { children }) => {
        // placeholder is used only when the token is not resolved
        return <React.Fragment>•••</React.Fragment>;
      },
      localizedPhoneNumber: () => <InlineLocalizedPhoneNumber />,
      locationSpecificTextSection: ({ children, value }) => (
        <LocationSpecificTextSection value={value}>
          {children}
        </LocationSpecificTextSection>
      ),
      linkToPage: (props) => {
        const path = props.value?.path;
        return (
          <React.Fragment>
            {path ? (
              <a href={path}>{props.children}</a>
            ) : (
              <React.Fragment>{props.children}</React.Fragment>
            )}
          </React.Fragment>
        );
      },
      linkToFile: (props) => {
        return (
          <React.Suspense fallback={<span>...</span>}>
            <LinkToFile {...props} />
          </React.Suspense>
        );
      }
    }
  };
